
const PrivatePage=()=>{
    return(
        <div>
            <p>We care about our privacy. Promise! </p>
        </div>
    )
}

export default PrivatePage;
