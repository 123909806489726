
const NotFoundPage=()=>{
    return(
        <div>
            <p>Page Not Fouund</p>
        </div>
    )
}

export default NotFoundPage;
